<script lang="ts" setup>
  import { getAssetsFile } from '@/utils'
  import SvgIcon from '@components/svgIcon/SvgIcon.vue'
  import 'swiper/css'
  import { Swiper as SwiperClass } from 'swiper/types'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { computed, ref } from 'vue'

  const swiperList = [
    {
      key: 1,
      logo: getAssetsFile('root/company-talk-logo_1.png'),
      name: '鸿星尔克',
      banner: getAssetsFile('root/company-talk-banner_1.png'),
      content:
        '区别于传统红包、转盘等强迫分享方式，将企业营销需求皃整融入游戏化解决方案，激励用户以炫耀性分享或组队闯关对抗，不再把获得实物奖励作为分享的唯一动力。',
    },
    {
      key: 2,
      logo: getAssetsFile('root/company-talk-logo_2.png'),
      name: '科勒',
      banner: getAssetsFile('root/company-talk-banner_2.png'),
      content:
        '5年前要是有人问我们该怎么做营销?我们会回答渠道+内容。但现在，内容引擎交出了更好的答案，那便是用户+内容。驱动用户、激励用户，让用户成为我们的“玩家”，这样的营销模式非常适用于当下的新零售企业。',
    },
    {
      key: 3,
      logo: getAssetsFile('root/company-talk-logo_3.png'),
      name: '恒安集团',
      banner: getAssetsFile('root/company-talk-banner_3.png'),
      content: '刚刚接触游戏化营销的人可能还不太了解它的魔力，我们多次尝试后认为它有可能为企业营销带来新的变革。',
    },
    {
      key: 4,
      logo: getAssetsFile('root/company-talk-logo_4.png'),
      name: '韩后',
      banner: getAssetsFile('root/company-talk-banner_4.png'),
      content:
        '内容引擎所供给游戏化营销模式，改变了企业与用户之间的关系。这不再是过往的单方面投放与输出，而是企业与用户互利互惠，为足够内卷的美妆行业带来了新的生机。',
    },
    {
      key: 5,
      logo: getAssetsFile('root/company-talk-logo_5.png'),
      name: '爱逸食品',
      banner: getAssetsFile('root/company-talk-banner_5.png'),
      content: '通过对用户心理的拆解，游戏化营销真正做到了“用户满意”与“降本增效”的并存。',
    },
  ]
  const swiperObj = ref<SwiperClass>()
  const setControllerSwiper = (swiper: SwiperClass) => {
    swiperObj.value = swiper
  }
  const isStart = computed(() => swiperObj.value?.isBeginning)
  const isEnd = computed(() => swiperObj.value?.isEnd)
</script>

<template>
  <div class="company-talk-page">
    <swiper
      slidesPerView="auto"
      :spaceBetween="24"
      :slidesOffsetBefore="80"
      :slidesOffsetAfter="24"
      :centeredSlides="false"
      :allowTouchMove="false"
      @swiper="setControllerSwiper"
      class="company-talk-swiper"
    >
      <swiper-slide v-for="item in swiperList" :key="item.key">
        <div class="company-talk-item">
          <div class="item-top">
            <img :src="item.logo" class="item-top-logo" />
            <span class="item-top-name">{{ item.name }}</span>
          </div>
          <div class="item-middle">
            <img :src="item.banner" />
          </div>
          <div class="item-bottom">
            <div class="item-bottom-content">{{ item.content }}</div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="controller">
      <div class="controller-item" @click="swiperObj?.slidePrev()">
        <svg-icon icon-name="prev" icon-size="14" :color="isStart ? '#9298a4' : '#495367'"></svg-icon>
      </div>
      <div class="controller-item" @click="swiperObj?.slideNext()">
        <svg-icon icon-name="next" icon-size="14" :color="isEnd ? '#9298a4' : '#495367'"></svg-icon>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  :deep(.swiper-slide) {
    width: 375px;
  }

  .company-talk-page {
    .company-talk-item {
      box-sizing: border-box;
      // width: 376px;
      width: 100%;
      height: 511px;
      padding: 49px 20px 0;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgb(161 178 219 / 30%);

      .item-top {
        display: flex;
        align-items: center;

        .item-top-logo {
          width: 48px;
          margin-right: 12px;
        }

        .item-top-name {
          color: @black;
          font-weight: 500;
          font-size: 24px;
          font-family: PingFangSC-Medium, 'PingFang SC', sans-serif;
          line-height: 33px;
        }
      }

      .item-middle {
        width: 100%;
        margin-top: 20px;

        img {
          width: 100%;
        }
      }

      .item-bottom {
        margin-top: 16px;
        color: @black;
        font-weight: 400;
        font-size: 16px;
        font-family: PingFangSC-Regular, 'PingFang SC', sans-serif;
        line-height: 28px;
      }
    }

    .controller {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      .controller-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        margin-right: 16px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 20px 0 #d7e0ed;
        cursor: pointer;
      }

      .disabled {
        color: #9298a4;
      }
    }
  }
</style>
