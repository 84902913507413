<script setup lang="ts">
  import { eventReport, gotoHome } from '@/composable'
  import { showViewAutoPlay } from '@/composable/swiper'
  import { BtnType, ColorType, LinkToHomeType } from '@/define'
  import { getAssetsFile } from '@/utils'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import SwiperCore, { Autoplay } from 'swiper'
  import 'swiper/css'
  import { Swiper as SwiperClass } from 'swiper/types'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { ref } from 'vue'

  SwiperCore.use([Autoplay])

  const tabList = [
    {
      key: 1,
      name: '极简操作',
      content: {
        banner: getAssetsFile('root/core-banner-1.webm', 'videos'),
        title: '3分钟即可完成一场<br/>趣味活动的创建',
        content: `<div><img src="${getAssetsFile(
          'root/core-content-num_1.png'
        )}" />输入文字</div><div><img src="${getAssetsFile(
          'root/core-content-num_2.png'
        )}" />替换图片</div><div><img src="${getAssetsFile('root/core-content-num_3.png')}" />配置奖品</div>`,
      },
    },
    {
      key: 2,
      name: '海量模板',
      content: {
        banner: getAssetsFile('root/core-banner-2.webm', 'videos'),
        title: '满足不同企业、不同场景的营销诉求',
        content: '上千种营销玩法<br/>海量游戏化营销模板<br/>正版商用素材',
      },
    },
    {
      key: 3,
      name: '多渠道分发',
      content: {
        banner: getAssetsFile('root/core-banner-3.webm', 'videos'),
        title: '让企业获客不受限',
        content: '匹配不同流量生态<br/>支持多渠道分发',
      },
    },
    {
      key: 4,
      name: '多维数据洞察',
      content: {
        banner: getAssetsFile('root/core-banner-4.webm', 'videos'),
        title: '让活动效果可知、可控、可感',
        content: '支持不同维度的数据监测<br/>提供用户画像数据',
      },
    },
    {
      key: 5,
      name: '企业协作提效',
      content: {
        banner: getAssetsFile('root/core-banner-5.webm', 'videos'),
        title: '让执行更流畅',
        content: '支持多人协作<br/>告别跨应用的繁琐沟通',
      },
    },
  ]
  // const currentIndex = ref(1)
  // const handleClick = (index: number) => {
  //   currentIndex.value = index
  // }
  const currentIndex = ref(0)
  const handleClick = (index: number, key: number) => {
    eventReport({
      event_id: 'pc_official_website_function_change',
      parm: key,
    })
    swiperObj.value?.slideTo(index)
  }
  const onSlideChange = (swiper: SwiperClass) => {
    currentIndex.value = swiper.activeIndex
  }
  const swiperObj = ref<SwiperClass>()
  const setControllerSwiper = (swiper: SwiperClass) => {
    swiperObj.value = swiper
  }

  const swiperRef = ref<HTMLElement | null>(null)
  showViewAutoPlay(swiperRef, swiperObj)
</script>

<template>
  <div ref="swiperRef" class="core-function-page">
    <div class="tab-box">
      <template v-for="(item, index) in tabList" :key="item.key">
        <div class="tab-point" :class="{ active: currentIndex === index }" @click="handleClick(index, item.key)">
          <span>{{ item.name }}</span>
          <!-- <icon-caret-right v-if="currentIndex === index" class="icon-right" /> -->
        </div>
      </template>
    </div>
    <!-- <a-carousel
      v-model:current="currentIndex"
      indicator-type="never"
      direction="vertical"
      :default-current="1"
      auto-play
      show-arrow="never"
      class="carousel-box"
    >
      <a-carousel-item v-for="{ content, key } in tabList" :key="key">
        <div class="carousel-item">
          <div class="left">
            <video :src="content.banner" :loop="true" autoplay muted />
          </div>
          <div class="right">
            <div class="title" v-html="content.title"></div>
            <div class="content" v-html="content.content"></div>
            <div class="btn">
              <base-btn :color="ColorType.PRIMARY" :type="BtnType.SHOW">同款模板 >></base-btn>
            </div>
          </div>
        </div>
      </a-carousel-item>
    </a-carousel> -->
    <swiper
      :slidesPerView="1"
      :centeredSlides="true"
      :allowTouchMove="false"
      :autoplay="{ pauseOnMouseEnter: true, disableOnInteraction: false, delay: 10000 }"
      direction="vertical"
      :speed="600"
      @swiper="setControllerSwiper"
      @slideChange="onSlideChange"
      class="carousel-box"
    >
      <swiper-slide v-for="{ content, key } in tabList" :key="key">
        <div class="carousel-item">
          <div class="left">
            <video :src="content.banner" :loop="true" autoplay muted />
          </div>
          <div class="right">
            <div class="title" v-html="content.title"></div>
            <div class="content" v-html="content.content"></div>
            <div class="btn">
              <base-btn :color="ColorType.PRIMARY" :type="BtnType.SHOW" @click="gotoHome(LinkToHomeType.COMPETENCE)"
                >立即免费使用</base-btn
              >
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style lang="less" scoped>
  .core-function-page {
    display: flex;
    width: 100%;
    height: 600px;
    overflow: hidden;
    border-radius: 10px;
  }

  .tab-box {
    display: flex;
    flex-direction: column;
    width: 248px;
    height: 100%;
  }

  .tab-point {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20%;
    color: @gray-4;
    font-size: 24px;
    font-family: PingFangSC-Regular, 'PingFang SC', sans-serif;
    line-height: 33px;
    background: #e2eaff;
    border-bottom: 1px solid #cdd6ee;
    cursor: pointer;

    .tab-icon {
      width: 60px;
      margin-right: 14px;
    }

    .icon-right {
      position: absolute;
      right: -15px;
      z-index: 10;
      color: @primary-color;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .active {
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    font-family: PingFangSC-Semibold, 'PingFang SC', sans-serif;
    line-height: 42px;
    background: linear-gradient(90deg, #0ce5ed 0%, @primary-color 100%);
  }

  .carousel-box {
    flex: 1;
    height: 100%;

    .carousel-item {
      display: flex;
      box-sizing: border-box;
      height: 100%;
      padding: 60px 80px 60px 70px;
      background: #fff;
      opacity: 1;

      .left {
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 16px;

        video {
          width: 660px;
          height: 480px;
        }
      }

      .right {
        position: relative;
        flex: 1;
        padding-left: 70px;

        .title {
          color: @black;
          font-weight: 500;
          font-size: 36px;
          font-family: PingFangSC-Medium, 'PingFang SC', sans-serif;
          line-height: 54px;
        }

        .content {
          margin-top: 36px;
          color: #05c7d6;
          font-weight: 600;
          font-size: 24px;
          font-family: PingFangSC-Semibold, 'PingFang SC', sans-serif;
          line-height: 48px;

          :deep(div) {
            display: flex;
            align-items: center;

            img {
              width: 30px;
              margin-right: 8px;
            }
          }
        }

        .btn {
          position: absolute;
          bottom: 64px;
        }
      }
    }

    .swiper-slide {
      &:not(:first-child) {
        .carousel-item {
          .left {
            border: 2px solid #fff;
            box-shadow: 0 0 20px 0 #d7e0ed;
          }
        }
      }
    }
  }
</style>
