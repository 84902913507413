<script setup lang="ts">
  import { eventReport } from '@/composable'
  import { showViewAutoPlay } from '@/composable/swiper'
  import { BtnType, ColorType, DeployType, Template } from '@/define'
  import { useRootStore } from '@/store'
  import { getAssetsFile } from '@/utils'
  import { createScreen, linkToContent } from '@/utils/common'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import { openLogin } from '@components/popup/helpers'
  import { openPreview } from '@components/popup/preview/helpers'
  import SwiperCore, { Autoplay } from 'swiper'
  import 'swiper/css'
  import { Swiper as SwiperClass } from 'swiper/types'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { ref } from 'vue'

  SwiperCore.use([Autoplay])

  const TEMPLATES_CONFIG: Record<DeployType, Template[]> = {
    [DeployType.LOCAL]: [
      {
        id: 496,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 471,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 0,
        type: 0,
        templateType: 'mini',
      },
      {
        id: 342,
        type: 0,
        templateType: 'screen',
      },
    ],
    [DeployType.ALPHA]: [
      {
        id: 496,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 471,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 0,
        type: 0,
        templateType: 'mini',
      },
      {
        id: 342,
        type: 0,
        templateType: 'screen',
      },
    ],
    [DeployType.BVT]: [
      {
        id: 172,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 155,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 0,
        type: 0,
        templateType: 'mini',
      },
      {
        id: 342,
        type: 0,
        templateType: 'screen',
      },
    ],
    [DeployType.OPERATE]: [
      {
        id: 17,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 269,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 0,
        type: 0,
        templateType: 'mini',
      },
      {
        id: 0,
        type: 0,
        templateType: 'screen',
      },
    ],
    [DeployType.PROD]: [
      {
        id: 554,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 522,
        type: 3,
        templateType: 'hd',
      },
      {
        id: 0,
        type: 0,
        templateType: 'mini',
      },
      {
        id: 342,
        type: 0,
        templateType: 'screen',
      },
    ],
  }
  const templates = TEMPLATES_CONFIG[import.meta.env.VITE_DEPLOY_TYPE]

  const tabList = [
    {
      key: 1,
      name: '裂变引流',
      noCheckImg: getAssetsFile('root/game-case-nocheck_1.png'),
      checkImg: getAssetsFile('root/game-case-check_1.png'),
      content: {
        template: templates[0],
        banner: getAssetsFile('root/game-case-banner_1.mp4', 'videos'),
        title: '告别“砸钱投放”<br/>游戏化营销更懂用户心理',
        content:
          '在韩菲诗「击鼓开运」游戏中，用户若是挑战失败，分享游戏即可获得复活机会，在「挑战心理」的驱动下，用户会主动邀请好友注册，有效降低新客注册成本',
      },
    },
    {
      key: 2,
      name: '促活留存',
      noCheckImg: getAssetsFile('root/game-case-nocheck_2.png'),
      checkImg: getAssetsFile('root/game-case-check_2.png'),
      content: {
        template: templates[1],
        banner: getAssetsFile('root/game-case-banner_2.mp4', 'videos'),
        title: '告别“人工召回”<br/>游戏化营销更懂习惯养成',
        content:
          '在A1「“糕”颜值挑战」中，通过设置每日限时挑战，为排行榜TOP用户派发实物奖品，在「挑战」+「损失规避」的驱动下，用户每日这个时段都会打开商城，从而降低促活成本',
      },
    },
    {
      key: 3,
      name: '会员转化',
      noCheckImg: getAssetsFile('root/game-case-nocheck_3.png'),
      checkImg: getAssetsFile('root/game-case-check_3.png'),
      content: {
        // 淘宝游戏，不支持预览
        template: templates[2],
        banner: getAssetsFile('root/game-case-banner_3.mp4', 'videos'),
        title: '告别“发券入会”<br/>游戏化营销更懂用户引导',
        content:
          '在养成类游戏中，通过设置新手任务，引导用户入会，赠予道具奖励。在「物质激励」的驱动下，用户对入会的排斥感降低，入会意愿明显提升',
      },
    },
    {
      key: 4,
      name: '线下活动',
      noCheckImg: getAssetsFile('root/game-case-nocheck_4.png'),
      checkImg: getAssetsFile('root/game-case-check_4.png'),
      content: {
        template: templates[3],
        banner: getAssetsFile('root/game-case-banner_4.mp4', 'videos'),
        title: '告别“地推获客”<br/>游戏化营销更懂用户焦点',
        content:
          '在线下活动中，通过放置大屏游戏，吸引用户主动驻足观看，在「好奇心理」的驱动下，用户主动参与游戏，完成注册任务，有效提升线下获客效率',
      },
    },
  ]
  const currentIndex = ref(0)
  const handleClick = (index: number, key: number) => {
    eventReport({
      event_id: 'pc_official_website_solution_change',
      parm: key,
    })
    swiperObj.value?.slideTo(index)
  }
  const onSlideChange = (swiper: SwiperClass) => {
    currentIndex.value = swiper.activeIndex
  }
  const swiperObj = ref<SwiperClass>()
  const setControllerSwiper = (swiper: SwiperClass) => {
    swiperObj.value = swiper
  }
  const onUseTemplate = (template: Template) => {
    switch (template.templateType) {
      case 'hd':
        eventReport({
          event_id: 'pc_recommend_use_same_click',
          obj_type: 1,
          obj_id: template.id,
        })
        openPreview(template.id, template.type)
        break
      case 'mini':
        linkToContent('/marketTool/hd')
        break
      case 'screen':
        // eventReport({
        //   event_id: 'pc_recommend_use_same_click',
        //   obj_type: 2,
        //   obj_id: template.id,
        // })
        if (!useRootStore().isLogin) {
          openLogin({
            onHide: () => {
              if (useRootStore().isLogin) {
                createScreen(template.id)
              }
            },
          })
          return
        }
        createScreen(template.id)
        break
    }
  }

  const swiperRef = ref<HTMLElement | null>(null)
  showViewAutoPlay(swiperRef, swiperObj)
</script>

<template>
  <div ref="swiperRef">
    <div class="tab-box">
      <template v-for="(item, index) in tabList" :key="item.key">
        <div class="tab-point" :class="{ active: currentIndex === index }" @click="handleClick(index, item.key)">
          <img class="tab-icon" :src="currentIndex === index ? item.checkImg : item.noCheckImg" />
          <span>{{ item.name }}</span>
          <div class="icon-down" v-if="currentIndex === index"></div>
        </div>
      </template>
    </div>
    <swiper
      :slidesPerView="1"
      :centeredSlides="true"
      :allowTouchMove="false"
      :autoplay="{ pauseOnMouseEnter: true, disableOnInteraction: false, delay: 100000 }"
      :speed="600"
      @swiper="setControllerSwiper"
      @slideChange="onSlideChange"
      class="carousel-box"
    >
      <swiper-slide v-for="{ content, key } in tabList" :key="key">
        <div class="carousel-item">
          <div class="left">
            <video :src="content.banner" :loop="true" autoplay muted></video>
          </div>
          <div class="right">
            <div class="title" v-html="content.title"></div>
            <div class="divider"></div>
            <div class="content">
              {{ content.content }}
            </div>
            <div class="btn">
              <base-btn :color="ColorType.PRIMARY" :type="BtnType.SHOW" @click="onUseTemplate(content.template)"
                >同款模板 >></base-btn
              >
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<style lang="less" scoped>
  .tab-box {
    display: flex;
    height: 124px;
    margin-bottom: 39px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 #d7e0ed;
  }

  .tab-point {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    color: @black;
    font-weight: 500;
    font-size: 24px;
    font-family: PingFangSC-Medium, 'PingFang SC', sans-serif;
    background: #fff;
    cursor: pointer;

    .tab-icon {
      width: 60px;
      margin-right: 14px;
    }

    .icon-down {
      position: absolute;
      bottom: -20px;
      width: 0;
      height: 0;
      color: @primary-color;
      border: 10px solid transparent;
      border-top-color: white;
    }

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }

  .active {
    color: #fff;
    background: @primary-color;

    .icon-down {
      border-top-color: @primary-color;
    }
  }

  .carousel-box {
    height: 564px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 #d7e0ed;

    .carousel-item {
      display: flex;
      height: 100%;
      background: #fff;
      opacity: 1;

      .left {
        video {
          width: 640px;
          height: 564px;
        }
      }

      .right {
        position: relative;
        padding: 70px 60px 0 100px;

        .title {
          color: @black;
          font-weight: 500;
          font-size: 36px;
          font-family: PingFangSC-Medium, 'PingFang SC', sans-serif;
          line-height: 54px;
        }

        .divider {
          width: 36px;
          height: 6px;
          margin: 24px 0;
          background: @primary-color;
          border-radius: 4px;
        }

        .content {
          color: #646d7d;
          font-weight: 400;
          font-size: 18px;
          font-family: PingFangSC-Regular, 'PingFang SC', sans-serif;
          line-height: 30px;
        }

        .btn {
          position: absolute;
          bottom: 92px;
        }
      }
    }
  }
</style>
