<script lang="ts" setup>
  import { gotoHome, pageReport } from '@/composable'
  import { BtnType, ColorType, LinkToHomeType } from '@/define'
  import BaseBtn from '@components/btn/BaseBtn.vue'
  import { useHead } from '@vueuse/head'
  import CompanyTalk from './components/CompanyTalk.vue'
  import CoreFunction from './components/CoreFunction.vue'
  import GameCase from './components/GameCase.vue'

  useHead({
    title: '内容引擎_低成本更有趣的活动模板，轻松解决企业营销难题',
    meta: [
      {
        name: 'description',
        content:
          '【内容引擎_低成本更有趣的活动模板，轻松解决企业营销难题】是内容引擎为您精心挑选的关于【低成本更有趣的活动模板】的相关模板资源信息，共涉及相关场景的模板资源推荐。内容引擎是一个简单高效的游戏化营销活动创建平台，提供大量免费设计素材和游戏化模板、H5、小程序，可为您提供更高效的营销活动创建，三分钟就能做出一场高转化的活动。在线即可完成制作，轻松解决企业营销问题。',
      },
      {
        name: 'keywords',
        content: '营销,游戏化,互动,H5,活动,低成本,获客,引流,裂变,留存,转化',
      },
    ],
  })

  const isSafari = () => {
    if (!import.meta.env.SSR) {
      return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    } else {
      return false
    }
  }

  pageReport({
    page: 'pc_official_website',
  })
</script>

<template>
  <div class="home-page">
    <div class="home-header">
      <div class="home-header-box">
        <div class="left">
          <div class="title">
            <div>游戏化营销</div>
            <div>企业增长新玩法</div>
          </div>
          <div class="tips">仅需3分钟，让营销更有趣，让转化更高效</div>
          <div class="btn">
            <base-btn :type="BtnType.SHOW" :color="ColorType.PRIMARY" @click="gotoHome(LinkToHomeType.ROOTBANER)"
              >立即体验</base-btn
            >
          </div>
        </div>
        <div class="right">
          <div>
            <video
              v-if="isSafari()"
              class="phone"
              src="@/assets/videos/root/header-game-safari.mp4"
              autoplay
              muted
              :loop="true"
            ></video>
            <video v-else class="phone" src="@/assets/videos/root/header-game.webm" :loop="true" autoplay muted></video>
          </div>
          <div>
            <video
              v-if="isSafari()"
              class="chart"
              src="@/assets/videos/root/header-data-safari.mp4"
              autoplay
              muted
            ></video>
            <video v-else class="chart" src="@/assets/videos/root/header-data.webm" autoplay muted></video>
          </div>
        </div>
      </div>
    </div>
    <div class="game-case">
      <div class="main-title">四大游戏化解决方案 让营销更简单</div>
      <game-case></game-case>
    </div>
    <div class="core-function">
      <div class="core-function-main">
        <div class="main-title" style="color: #fff">五大核心能力 让营销更轻松</div>
        <core-function></core-function>
      </div>
    </div>
    <div class="companys">
      <div class="main-title">上百家企业营销首选</div>
      <div>
        <img src="@images/root/companys.png" />
      </div>
    </div>
    <div class="company-talk">
      <div class="company-talk-main">
        <div class="main-title">企业谈内容引擎</div>
        <company-talk></company-talk>
      </div>
    </div>
    <div class="data-box">
      <div class="data-box-main">
        <div class="data-box-item">
          <div class="num">300<span class="unit">万</span></div>
          <div class="directions">用户信赖之选</div>
        </div>
        <a-divider :margin="133" direction="vertical" />
        <div class="data-box-item">
          <div class="num">4380<span class="unit">天</span></div>
          <div class="directions">安全运行</div>
        </div>
        <a-divider :margin="133" direction="vertical" />
        <div class="data-box-item">
          <div class="num">824<span class="unit">万</span></div>
          <div class="directions">累计上线活动</div>
        </div>
        <a-divider :margin="133" direction="vertical" />
        <div class="data-box-item">
          <div class="num">5823<span class="unit">万</span></div>
          <div class="directions">累计触达流量</div>
        </div>
      </div>
    </div>
    <div class="marketing-box">
      <div class="marketing-box-main">
        <div class="title">游戏营销，就用内容引擎</div>
        <div class="btn">
          <base-btn
            :type="BtnType.SHOW"
            :color="ColorType.WHITE"
            :font-size="16"
            @click="gotoHome(LinkToHomeType.FOOTERGUIDE)"
            >立即体验 >></base-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .main-title {
    width: 100%;
    margin-bottom: 60px;
    color: @black;
    font-weight: 600;
    font-size: 40px;
    font-family: PingFangSC-Semibold, 'PingFang SC', sans-serif;
    line-height: 56px;
    text-align: center;
  }

  .home-page {
    .home-header {
      height: 680px;
      background-image: url('@images/root/home-bg.jpg');
      background-size: cover;

      .home-header-box {
        display: flex;
        align-items: center;
        width: 1440px;
        height: 100%;
        margin: 0 auto;

        .left {
          .title {
            color: @black;
            font-weight: bold;
            font-size: 66px;
            font-family: AlibabaPuHuiTiB, sans-serif;
            line-height: 80px;
          }

          .tips {
            margin-top: 30px;
            color: @gray-5;
            font-size: 24px;
            font-family: PingFangSC-Regular, 'PingFang SC', sans-serif;
            line-height: 33px;
          }

          .btn {
            margin-top: 86px;
          }
        }

        .right {
          display: flex;

          .phone {
            width: 340px;
          }

          .chart {
            width: 590px;
          }
        }
      }
    }

    .game-case {
      width: 1440px;
      margin: 0 auto;
      margin-top: 90px;
    }

    .core-function {
      margin-top: 90px;
      padding: 90px 0 106px;
      background-image: url('@images/root/core-bg.jpg');
      background-size: cover;

      .core-function-main {
        width: 1440px;
        margin: 0 auto;
      }
    }

    .companys {
      width: 1440px;
      margin: 0 auto;
      margin-top: 90px;

      img {
        width: 100%;
      }
    }

    .company-talk {
      width: 100%;
      margin-top: 90px;
      padding: 80px 0 90px;
      background: #f5f9ff;

      .company-talk-main {
        // width: 1440px;
        // margin: 0 auto;
      }
    }

    .data-box {
      .data-box-main {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 259px;

        :deep(.arco-divider-vertical) {
          height: 63px;
        }

        .data-box-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .num {
            color: @primary-color;
            font-weight: bold;
            font-size: 36px;
            font-family: Oswald-Bold, Oswald, sans-serif;
            line-height: 53px;

            .unit {
              margin-left: 4px;
              font-weight: 600;
              font-size: 18px;
              font-family: PingFangSC-Semibold, 'PingFang SC', sans-serif;
              line-height: 25px;
            }
          }
        }
      }
    }

    .marketing-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 310px;
      background-image: url('@images/root/marketing-bg.jpg');
      background-size: cover;

      .marketing-box-main {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          color: #fff;
          font-weight: 500;
          font-size: 40px;
          font-family: PingFangSC-Medium, 'PingFang SC', sans-serif;
          line-height: 56px;
        }

        .btn {
          margin-top: 24px;
        }
      }
    }
  }
</style>
